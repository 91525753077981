import(/* webpackMode: "eager" */ "/vercel/path0/apps/app/app/global.css");
import(/* webpackMode: "eager" */ "/vercel/path0/libs/shared/ui/src/lib/auth-success/auth-success.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/libs/shared/ui/src/lib/cookie-consent/cookie-consent.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/libs/shared/ui/src/lib/entities-table/entities-table.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/libs/shared/ui/src/lib/entity-modal/entity-modal.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/libs/shared/ui/src/lib/faq/faq.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/libs/shared/ui/src/lib/homepage/header/header.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/libs/shared/ui/src/lib/institution-search/institution-search.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/libs/shared/ui/src/lib/navigation/nav-header/nav-header.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/libs/shared/ui/src/lib/news-banner/news-banner.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/libs/shared/ui/src/lib/not-found/not-found.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/libs/shared/ui/src/lib/posthog-setup/posthog-setup.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/libs/shared/ui/src/lib/toastContainerWrapper/toastContainerWrapper.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/libs/shared/ui/src/lib/verification-data/verification-data.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/libs/shared/ui/src/lib/verification-demo-button/verification-demo-button.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fontsource/ubuntu/300.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fontsource/ubuntu/400.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fontsource/ubuntu/500.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fontsource/ubuntu/700.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-error-boundary/dist/react-error-boundary.esm.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-toastify/dist/react-toastify.esm.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/vanilla-cookieconsent/dist/cookieconsent.css")